import { SelectInput, required } from 'react-admin';

const TYPE_OF_VISITORS = [
  'ATTENDEE',
  'SPEAKER',
  'VIP',
  'STAFF'
];

const SelectTypeOfVisitor = ({source}: {source: string}) => {
  const choices = TYPE_OF_VISITORS.map((type) => ({
    id: type,
    name: `resources.Participant.fields.typeOfVisitors.${type}`
  }))

  return(
    <SelectInput source={source} choices={choices} validate={required()} defaultValue="CA" />
)};

export default SelectTypeOfVisitor;