import {useEffect } from "react";
import Loading from ".//views/loading";
import { Admin, CustomRoutes, useSafeSetState, Authenticated, Resource } from 'react-admin';
import { Route } from "react-router-dom";
import { WerifyLayout } from './views/layout';
import { Dashboard } from './views/dashboard';
import Login from './views/login';
import WerifyTheme from './theme';
import Graphiql from "./views/graphiql";
import authProvider from "./lib/auth_provider";
import { defaultDataProvider } from "./lib/data_provider";
import { OneTimeLogin, OneTimeLoginList, OneTimeLoginShow } from "./views/one_time_login";
import {
  PermIdentity, Event, Email, TextSnippet, AccountCircle, Inventory, BarChart, Inventory2Outlined,
  DriveFileMove, AlternateEmail, CardMembership, VpnKey, Hub, ConnectedTv,
} from '@mui/icons-material';
import { PersonList, PersonShow } from "./views/person";
import { EmailList, EmailShow } from "./views/email_address";
import ResourceLayout, { ResourceLayoutWrite } from "./views/admin_layout";
import { SessionList, SessionShow } from "./views/session";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { messages, browserLocale, LOCALES } from "./i18n";
import _ from 'lodash';
import { AccountStateEdit } from "./views/account_state";
import { EventList, EventShow, EventCreate, EventEdit } from "./views/event";
import {ParticipantList, ParticipantShow, ParticipantCreate} from './views/participant';
import { OutgoingEmailMessageList, OutgoingEmailMessageShow } from "./views/outgoing_email_message";
import { LoginRedirect } from "./views/login_redirect";


function App() {
  const [dataProvider, setDataProvider] = useSafeSetState<any>(null);
  const [databaseLocale, setDatabaseLocale] = useSafeSetState(null);
  const [reloadLang, setReloadLang] = useSafeSetState(null);

  useEffect(() => {
    async function initApp() {
      const dataProv = await defaultDataProvider();
      setDataProvider(dataProv);
      const lang = await authProvider.getLang();
      setDatabaseLocale(_.toLower(lang));
    }
    initApp();
  }, [databaseLocale, reloadLang]);

  const finalLocale = _.includes(LOCALES, databaseLocale) ? databaseLocale : browserLocale;
  const i18nProvider =  polyglotI18nProvider(locale => messages[locale], finalLocale);

  if (!dataProvider || !i18nProvider) { return <Loading /> };

  return (
    <Admin
      disableTelemetry
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      theme={WerifyTheme}
      layout={WerifyLayout}
     >
        <CustomRoutes noLayout >
          <Route path="/graphiql" element={<Authenticated><Graphiql /></Authenticated>} />
          <Route path="/one_time_login/:access_token" element={<OneTimeLogin setReloadLang={setReloadLang} />} />
          <Route path="/login_redirect" element={<LoginRedirect />} />
        </CustomRoutes>
      
        <Resource
          name="AccountState"
          edit={<ResourceLayoutWrite><AccountStateEdit /></ResourceLayoutWrite>}
        />
        <Resource
          name="Person"
          list={<ResourceLayout><PersonList /></ResourceLayout>}
          show={<ResourceLayout><PersonShow /></ResourceLayout>}
          icon={PermIdentity}
        />
        <Resource
          name="EmailAddress"
          list={<ResourceLayout><EmailList /></ResourceLayout>}
          show={<ResourceLayout><EmailShow /></ResourceLayout>}
          icon={AlternateEmail}
        />
        <Resource
          name="Event"
          list={<ResourceLayout><EventList /></ResourceLayout>}
          show={<ResourceLayout><EventShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><EventCreate /></ResourceLayoutWrite>}
          edit={<ResourceLayoutWrite><EventEdit /></ResourceLayoutWrite>}
          icon={Event}
        />,
        <Resource
          name="Participant"
          list={<ResourceLayout><ParticipantList /></ResourceLayout>}
          show={<ResourceLayout><ParticipantShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><ParticipantCreate /></ResourceLayoutWrite>}
          icon={PermIdentity}
        />,
        <Resource
          name="OutgoingEmailMessage"
          list={<ResourceLayout><OutgoingEmailMessageList /></ResourceLayout>}
          show={<ResourceLayout><OutgoingEmailMessageShow /></ResourceLayout>}
          icon={Email}
        />,
        <Resource
          name="Session"
          list={<ResourceLayout><SessionList /></ResourceLayout>}
          show={<ResourceLayout><SessionShow /></ResourceLayout>}
          icon={ConnectedTv}
        />
        <Resource
          name="OneTimeLogin"
          list={<ResourceLayout><OneTimeLoginList /></ResourceLayout>}
          show={<ResourceLayout><OneTimeLoginShow /></ResourceLayout>}
          icon={VpnKey}
        />
    </Admin>
  );
}

export default App;
