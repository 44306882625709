import { SelectInput, useGetList, useTranslate } from 'react-admin';
import type { Validator } from 'react-admin';

interface SelectTemplatesInterface {
  source: string,
  validate?: Validator | Validator[],
  alwaysOn?: boolean,
}

const SelectEvents = ({source, validate, alwaysOn}: SelectTemplatesInterface) => {
  const translate = useTranslate();
  const loadingValue = [{id: null, name: translate("resources.Event.loading")}];
  const { data: choices, isLoading } = useGetList(
    "Event",
    {
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'id', order: 'ASC'},
      filter: {}
    },
  );

  const optionRenderer = choice => choice.event ? `${choice.id} - ${choice.event}` : choice.id;

  return(
  <SelectInput
    source={source}
    validate={validate}
    alwaysOn={alwaysOn}
    choices={ isLoading ? loadingValue : choices }
    optionText={optionRenderer}
    translateChoice={false}
    sx={{minWidth: "20em", maxWidth: "25em"}}
  />
)};

export default SelectEvents;