import { useEffect } from 'react';
import { Container, Button } from '@mui/material';
import { useTranslate, useSafeSetState, useRedirect } from 'react-admin';
import { Replay, ContactMail } from '@mui/icons-material';
import { Head1 } from '../theme';
import { ToolbarLayout } from './layout';
import Loading from './loading';
import { Settings } from '../Settings';
import _ from 'lodash';

const Login = () => {
  const translate = useTranslate();
  const [loadingPage, setLoadingPage] = useSafeSetState(true);
  const [url, setUrl] = useSafeSetState(false);
  const redirect = useRedirect();
  const controller = new AbortController();

  useEffect(() => {
    const loadOrg = async () => {
      try {
        let response = await fetch(`${Settings.apiHost}/info/login_werify_point`);
        if (!response.ok) { return; }
        const url = await response.text();
        setUrl(url);
      } catch { }

      setLoadingPage(false);
    }
    loadOrg();

    return () => { controller.abort() };
  }, []);

  if (loadingPage) {
    return <Loading/>
  }

  return (
    <ToolbarLayout
      loggedIn={false}
      logoUrl={ null }
      fgColor={ "#111" }
      bgColor={ "#fafafa" }
      footerHtml={ false }
    >
        <Container maxWidth="sm" id="login-form-container" sx={{mb: "2em"}}>
          <Head1 sx={{my: 3}}> { translate("components.login.title") } </Head1>
          <Button
            id="login-button"
            variant="contained"
            size="large"
            fullWidth
            startIcon={<ContactMail />}
            onClick={() => redirect(url)}
          >
            { translate("components.login.login_button") }
          </Button>
        </Container>
    </ToolbarLayout>
  );
};

export default Login;
