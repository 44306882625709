import { SelectInput, required } from 'react-admin';

const LANGS = [
  'CA',
  'ES',
  'EN',
  'EU',
  'FR',
  'IT',
  'GL',
  'PT'
];

const SelectLang = ({source, availableLangs}: {source: string, availableLangs?: any[]}) => {
  const langs = availableLangs ? availableLangs : LANGS;
  const choices = langs.map((lang) => ({
    id: lang,
    name: `resources.Participant.fields.langs.${lang}`
  }))

  return(
    <SelectInput source={source} choices={choices} validate={required()} defaultValue="CA" />
)};

export default SelectLang;