const development = {
  apiHost: "http://localhost:8000",
  recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
  environment: 'development',
  help_url: "https://localhost:8000/#/help",
  altmeRedirectUri: "https://8lh8dmll-8000.brs.devtunnels.ms",
}

const all = {
  "http://localhost:8000": development,
  "http://localhost:3000": development,
  "https://8lh8dmll-3000.brs.devtunnels.ms": development,
  "https://8lh8dmll-8000.brs.devtunnels.ms": development,
  "https://weripass-staging.werify.eu": {
    apiHost: "https://weripass-staging.werify.eu",
    recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
    environment: 'staging',
    help_url: "https://staging.werify.eu/#/help",
    altmeRedirectUri: "https://staging.werify.eu",
  },
  "https://weripass.werify.eu": {
    apiHost: "https://points.werify.eu",
    recaptchaSiteKey: "6LcpP-cpAAAAAFiqbZfI8HTbqKQvj68md3cFPtKc",
    environment: 'production',
    help_url: "https://points.werify.eu/#/help",
    altmeRedirectUri: "https://points.werify.eu",
  }
}

export const Settings = all[window.origin];
