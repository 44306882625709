import { useEffect } from 'react';
import { Container } from '@mui/material';
import {
  useSafeSetState, useTranslate, useRedirect,
} from 'react-admin';
import Loading from './loading';


const Dashboard = () => {
  const redirect = useRedirect();

  useEffect(() => {
    redirect("/Person");
  }, []);

  return <Loading />;
}


export { Dashboard };