import { useEffect } from 'react';
import {
  List, Datagrid, ShowButton, TextField, FunctionField, Show, NumberField, DateField,
  SimpleShowLayout, useTranslate, ReferenceField, Button, TopToolbar, useSafeSetState
} from 'react-admin'
import { PaginationDefault, defaultSort } from "../components/utils";
import {PermIdentity, Settings} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { FieldCopyWithUrl } from "../components/copy_to_clipboard";
import TranslatedTextField from '../components/translated_textfield';
import authProvider, { getSessionAndSetIt, getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';
export const PersonIcon = PermIdentity;




function PersonList() {
  
  const [permissions, setPermissions] = useSafeSetState("");
  const [session, setSession] = useSafeSetState("");
  useEffect(() => {
    getPermissionsAndSetThem({authProvider, setPermissions});
    getSessionAndSetIt({setSession});
  }, []);
  const translate = useTranslate();

  const userFilters = [ <FilterTextInput source="idEq" alwaysOn /> ];

  const ActionsToolbar = () => <TopToolbar>
    {requirePermissions.canWriteAdminResources(permissions) &&
      <Button href={`#/AccountState/${session.orgId}`} label={translate("resources.Person.configAccountState")}>
        <Settings/>
      </Button>
    }
  </TopToolbar>


  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={userFilters}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<ActionsToolbar/>}
    >
      {personGrid}
    </List>
  );
}

const personGrid = 
  <Datagrid bulkActionButtons={false}>
    <TextField source='id' />
    <ReferenceField source="emailAddress" reference="EmailAddress" link="show" sortable={false}>
      <TextField source="address" />
    </ReferenceField>
    <TranslatedTextField source="lang" translation="resources.Person.fields.langs" />
    <TranslatedTextField source="role" translation="resources.Person.fields.roles" />
    <ShowButton />
  </Datagrid>


function PersonShow(){
  const translate = useTranslate();

  return (
    <Show>
      <SimpleShowLayout>
        <NumberField source='id' />
        <ReferenceField source="emailAddress" reference="EmailAddress" link="show">
          <TextField source="address" />
        </ReferenceField>
        <TranslatedTextField source="lang" translation="resources.Person.fields.langs" />
        <TranslatedTextField source="role" translation="resources.Person.fields.roles" />
      </SimpleShowLayout>
    </Show>
  );
}





export {PersonList, PersonShow, personGrid};
